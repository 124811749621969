.strat-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.strat-row {
  display: flex;
  flex-direction: row;
}

.strat-info {
  flex-basis: 500px;
  flex-grow: 2;
  padding-right: 8px;
}

.strat-media {
  min-width: 320px;
  flex-basis: 500px;
  flex-grow: 4;
}
