.room-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.room-thumbnail {
  max-width: 100%;
}

.popover-image-wrapper {
  display: flex;
  justify-content: center;
}

.room-full-image {
  box-sizing: border-box;
  max-height: 40vh;
  max-width: calc(50vw - 24px);
  zoom: 2.0;
  image-rendering: pixelated;
}
