.chapter-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.chapter-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 0;
}

.chapter-tile {
    height: 0;
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
